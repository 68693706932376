erpnext.ProductList = class {
	/* Options:
		- items: Items
		- settings: E Commerce Settings
		- products_section: Products Wrapper
		- preference: If preference is not list view, render but hide
	*/
	constructor(options) {
		Object.assign(this, options);

		if (this.preference !== "List View") {
			this.products_section.addClass("hidden");
		}

		this.products_section.empty();
		this.make();
	}

	make() {
		let me = this;
		let html = `<br><br>`;


		this.items.forEach(item => {
			if (item.in_stock && item.qty_on_sale > 0 || item.has_variants || (!item.in_stock && item.on_back_order > 0)) {
				let title = item.web_item_name || item.item_name || item.item_code || "";
				title =  title.length > 200 ? title.substr(0, 200) + "..." : title;

				html += `<div class='row list-row w-100 mb-4'>`;
				html += me.get_image_html(item, title, me.settings);
				html += me.get_row_body_html(item, title, me.settings);
				html += `</div>`;
			}
		});

		let $product_wrapper = this.products_section;
		$product_wrapper.append(html);
		this.bind_change_qty();
		this.bind_remove_cart_item();
	}

	bind_change_qty() {
			// bind update button
			function timedRefresh(timeoutPeriod){
					setTimeout("location.reload(true);", timeoutPeriod)
			}

			function throttle(fn, waitTime) {
					if (!window.fn_stack) window.fn_stack = [];

					return function() {
					const context = this
					const args = arguments

					window.fn_stack.push(setTimeout(function() {
							if (window.fn_stack.length === 1) {
									fn.apply(context, args)

							}
							window.fn_stack.pop(0);

					}, waitTime));
			}
			}

			$(".product-page").on("change", ".cart-qty", function() {
					var item_code = $(this).attr("data-item-code");
					var newVal = $(this).val();
					this.shopping_cart_update({item_code, qty: newVal});
			});

			$(".product-page").on('click', '.number-spinner button', function () {
				var btn = $(this),
					input = btn.closest('.number-spinner').find('input'),
					oldValue = input.val().trim(),
					newVal = 0;

				if (btn.attr('data-dir') == 'up') {
					var basketQty = parseInt(input.attr("basket-qty"));
					var stockQty = parseInt(input.attr("stock-qty"));
					newVal = parseInt(oldValue) + basketQty;
					if(newVal > stockQty){
						alert("Out of Stock");
						return;
					}
				} else {
					if (oldValue > 1) {
						var basketQty = parseInt(input.attr("basket-qty"));
						newVal = parseInt(oldValue) - basketQty;
					}
				}
				input.val(newVal);

				throttle(() => {
						erpnext.e_commerce.shopping_cart.shopping_cart_update({
								item_code: input.attr("data-item-code"),
								qty: parseInt(input.val().trim())
						});
				}, 1000)();

			});
	}

	bind_remove_cart_item() {
			function timedRefresh(timeoutPeriod){
					setTimeout("location.reload(true);", timeoutPeriod)
			}

			$(".product-page").on("click", ".remove-cart-item", (e) => {
					const $remove_cart_item_btn = $(e.currentTarget);
					var item_code = $remove_cart_item_btn.data("item-code");

					erpnext.e_commerce.shopping_cart.shopping_cart_update({
							item_code: item_code,
							qty: 0
					});
					timedRefresh(1000);
			});
	}

	get_image_html(item, title, settings) {
			let image = item.website_image;
			let wishlist_enabled = !item.has_variants && settings.enable_wishlist;
			let image_html = ``;

			if (image) {
					image_html += `
							<div class="col-2 border text-center rounded list-image">
									<a class="product-link product-list-link" href="/${ item.route || '#' }">
											<img itemprop="image" class="website-image h-100 w-100" alt="${ title }"
													src="${ image }">
									</a>
									${ wishlist_enabled ? this.get_wishlist_icon(item): '' }
							</div>
					`;
			} else {
					image_html += `
							<div class="col-2 border text-center rounded list-image">
									<a class="product-link product-list-link" href="/${ item.route || '#' }"
											style="text-decoration: none">
											<div class="card-img-top no-image-list">
													${ frappe.get_abbr(title) }
											</div>
									</a>
									${ wishlist_enabled ? this.get_wishlist_icon(item): '' }
							</div>
					`;
			}

			return image_html;
	}

	get_row_body_html(item, title, settings) {
			let body_html = `<div class='col-10 text-left'>`;
			body_html += this.get_title_html(item, title, settings);
			body_html += `</div>`;
			return body_html;
	}

	get_title_html(item, title, settings) {
			let title_html = `<div style="display: flex; margin-left: -15px;">`;
			title_html += `
					<div class="col-8" style="margin-right: -15px;">
							<a href="/${ item.route || '#' }">
									<div class="product-title mb-2">
									${ title }
									</div>
							</a>
					
			`;
			title_html += this.get_item_details(item, settings);
			title_html += `</div>`;
			if (settings.enabled) {
					title_html += `<div class="col-4 cart-action-container">`;
					title_html += this.get_primary_button(item, settings);
					title_html += `</div>`;
			}
			title_html += `</div>`;

			return title_html;
	}

	get_item_details(item, settings) {
			let details = `
					<p class="product-code">
							${ __(item.item_group) } | ${__("Item Code")} : ${ item.item_code }
					</p>
					<div class="mt-2" style="color: var(--gray-600) !important; font-size: 13px;">
							${ item.short_description || '' }
					</div>
					<div class="product-price">
							${ item.formatted_price || '' }
			`;
			
			if (item.formatted_mrp) {
				details += `
					<small class="striked-price">
						<s>${ item.formatted_mrp ? item.formatted_mrp.replace(/ +/g, "") : "" }</s>
					</small>
					<small class="ml-1 product-info-green">
						${ item.discount } OFF
					</small>
				`;
			}

			details += this.get_stock_availability(item, settings);
			details += `</div>`;

			return details;
	}

	get_stock_availability(item, settings) {
			if (settings.show_stock_availability && !item.has_variants) {
					if (!item.in_stock && item.on_back_order > 0) {
							return `
									<br>
									<span class="out-of-stock mt-2" style="color: var(--primary-color)">
											${ __("Available on backorder") }
									</span>
							`;
					} else if (item.qty_on_sale === 0) {
							return `
									<br>
									<span class="out-of-stock mt-2">${ __("Out of stock") }</span>
							`;
					}
			}
			return ``;
	}

	get_wishlist_icon(item) {
			let icon_class = item.wished ? "wished" : "not-wished";

			return `
					<div class="like-action-list ${ item.wished ? "like-action-wished" : ''}"
							data-item-code="${ item.item_code }">
							<svg class="icon sm">
									<use class="${ icon_class } wish-icon" href="#icon-heart"></use>
							</svg>
					</div>
			`;
	}

	get_primary_button(item, settings) {
		if (item.has_variants) {
			return `
				<a href="/${ item.route || '#' }">
					<div class="btn btn-sm btn-explore-variants btn mb-0 mt-0">
						${ __('Sizes and Prices') }
					</div>
				</a>
			`;
		} else if (settings.enabled && item.in_stock || (!item.in_stock && item.on_back_order > 0) || item.stock_qty > 0) {
			let disabled = item.is_free_item ? 'disabled="disabled"' : '';
				if (frappe.session.user !== "Guest") {
					if(!item.in_stock && item.on_back_order === 0){
						item.stock_qty = 0
					} else if(!item.in_stock && item.on_back_order > 0){
						item.stock_qty = item.on_back_order
					}
					let cartQtySection = `
						<div class="d-flex">
							<div class="input-group number-spinner mt-1 mb-4">
								<span class="input-group-prepend d-sm-inline-block">
									<button class="btn cart-btn" data-dir="dwn" ${disabled}>-</button>
								</span>
								<input class="form-control text-center cart-qty" value="${item.cart_qty}" data-item-code="${item.item_code}" basket-qty="${item.basket_qty}" stock-qty="${item.stock_qty}" style="max-width: 70px;" ${disabled}>
								<span class="input-group-append d-sm-inline-block">
									<button class="btn cart-btn" data-dir="up" ${disabled}>+</button>
								</span>
								<div class="remove-cart-item column-sm-view d-flex" data-item-code="${item.item_code}">
									<span>
										<svg class="icon sm remove-cart-item-logo" width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" id="icon-close">
											<path fill-rule="evenodd" clip-rule="evenodd" d="M4.146 11.217a.5.5 0 1 0 .708.708l3.182-3.182 3.181 3.182a.5.5 0 1 0 .708-.708l-3.182-3.18 3.182-3.182a.5.5 0 1 0-.708-.708l-3.18 3.181-3.183-3.182a.5.5 0 0 0-.708.708l3.182 3.182-3.182 3.181z" stroke-width="0"></path>
										</svg>
									</span>
								</div>
							</div>
						</div>`;
					
					let basketQtyMessage = item.basket_qty > 1 ? `<p style="margin-left:80px;"> ${ __("Sold in packs of {0}", [item.basket_qty]) }</p>` : '';
		
					return cartQtySection + basketQtyMessage;
					} else {
						return `
							<div id="${ item.name }" class="btn
								btn-sm btn-primary btn-add-to-cart-list mb-0
								${ item.in_cart ? 'hidden' : '' }"
								data-item-code="${ item.item_code }"
								style="margin-top: 0px !important; max-height: 30px; float: right;
									padding: 0.25rem 1rem; min-width: 135px;">
								<span class="mr-2">
									<svg class="icon icon-md">
										<use href="#icon-assets"></use>
									</svg>
								</span>
								${ settings.enable_checkout ? __('Add to Cart') :  __('Add to Quote') }
							</div>`;
					}
				} else {
					return ``;
				}
	}

};
